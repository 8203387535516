<script>
import {defineComponent} from "vue";
import Table from "@/components/tables/table.vue";

export default defineComponent({
  name: "CompletedTab",
  components: {
    Table,
  },
  data() {
    return {
      table_headers: [
        {
          field: "order",
          label: "Order",
          align: "center",
        },
        {
          field: "loading_type",
          label: "Loading Type",
          align: "center",
          searchType: "select",
          searchOptions: [
            {label: "All", value: ""},
            {label: "Container", value: "container"},
            {label: "Wagon", value: "wagon"},
            {label: "Empty Wagon", value: "wagon_empty"},
          ],
        },
        {
          field: "applications",
          label: "Applications",
          align: "center",
        },
        {
          field: "company",
          label: "Company",
          align: "center",
        },
        {
          field: "departure",
          label: "Departure",
          align: "center",
        },
        {
          field: "destination",
          label: "Destination",
          align: "center",
        },
        {
          field: "quantity",
          label: "Quantity",
          align: "center",
        },
        {
          field: "weight",
          label: "Weight",
          align: "center",
          searchable: false
        },
        {
          field: "agreed_rate",
          label: "Agreed Rate",
          align: "center",
        },
      ],
    };
  },
  methods: {
    getOrderLink(order) {
      switch (order.child_type) {
        case "container_order":
          return "orders_container_detail";
        case "wagon_order":
          return "orders_wagon_detail"
        case "wagon_empty_order":
          return "orders_empty_wagon_detail"
        default:
          return "orders_container_detail";
      }
    },
  },
});
</script>

<template>
  <div>
    <Table
        name="Completed Pre-Orders"
        url="/pre_order/list/completed/"
        :headers="table_headers"
        :searchable="true"
    >
      <template v-slot:order="{ row: order }">
        <span v-if="order.order" class="badge badge-soft-secondary fs-12">
          <router-link
              :to="{
              name: getOrderLink(order.order),
              params: { id: order.order.order_number },
            }"
              class="text-primary"
          >
            {{ order.order.order_number }}
          </router-link>
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:loading_type="{ row: order }">
        <span v-if="order.loading_type">
          {{ order.loading_type ? order.loading_type.replace(/_/g, " ").toUpperCase() : "" }} 
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:applications="{ row: order }">
        <div v-if="order.applications" class="text-primary">
          <span
              class="badge badge-gradient-secondary mx-1"
              v-for="appication in order.applications"
              :key="`order_application_` + appication.id"
          >
            <router-link
                :to="{
                name: 'application_update',
                params: { id: appication.id },
              }"
                class="link-light"
            >
              {{ appication.number }}
            </router-link>
          </span>
        </div>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:company="{ row: order }">
        <span v-if="order.company" class="fs-14">
          <router-link
              :to="{
              name: 'customer_profile',
              params: { slug: order.company.slug },
            }"
          >
            {{ order.company.name }}
          </router-link>
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:departure="{ row: order }">
        <span v-if="order.departure" class="fs-14">{{
            order.departure.name
          }}</span>
        <span v-else>--</span>
      </template>
      <template v-slot:destination="{ row: order }">
        <span v-if="order.destination" class="fs-14">{{
            order.destination.name
          }}</span>
        <span v-else>--</span>
      </template>
      <template v-slot:quantity="{ row: order }">
        <span v-if="order.quantity" class="fs-14">{{
            order.quantity
          }}</span>
        <span v-else>--</span>
      </template>
      <template v-slot:weight="{ row: order }">
        <span v-if="order.weight" class="fs-14">{{ order.weight }}</span>
        <span v-else>--</span>
      </template>
      <template v-slot:agreed_rate="{ row: order }">
        <span v-if="order.agreed_rate" class="fs-14"
        >${{ order.agreed_rate }}</span
        >
        <span v-else>--</span>
      </template>
    </Table>
  </div>
</template>

<style scoped></style>
